import React from 'react'
import axios from 'axios'
import { navigate, Link } from 'gatsby'
import logo from '../images/encounter-dating.png'
import * as storage from '../utils/storage'
import { addDays } from '../utils/date'
import Layout from '../components/layout'

axios.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error)
  }
)
class Login extends React.Component {
  state = {
    email: ``,
    password: ``,
    remember: true,
    loading: false,
    showPassword: false,
  }

  handleChange(event) {
    if (event.target.type === 'checkbox') {
      this.setState({
        [event.target.name]: event.target.checked,
      })
      return
    }

    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    for (let key in this.state) {
      if (key === 'loading' || key === 'remember' || key === 'showPassword')
        continue
      if (!this.state[key] || this.state[key] === '') {
        alert('Please complete all fields!')
        return
      }
    }
    this.setState({ loading: true })
    // console.log(this.state)
    // console.log(process.env)
    // @TODO: probably move to a utility class
    axios
      .post(`${process.env.API_BASE_URL}/oauth/token`, {
        client_id: `${process.env.OAUTH_ID}`,
        client_secret: `${process.env.OAUTH_SECRET}`,
        grant_type: 'password',
        username: this.state.email,
        password: this.state.password,
      })
      .then(res => {
        if (this.state.remember)
          localStorage.setItem('remember', this.state.remember)
        storage.setItem('access_token', res.data.access_token)
        axios
          .post(
            `${process.env.API_BASE_URL}/graphql`,
            {
              query: `query {
            me {
                id
                first_name
                last_name
                email
                age
                gender
                mobile
                location
                photo_url
                public_contacts
            }
          }`,
            },
            {
              headers: {
                Authorization: 'Bearer ' + res.data.access_token,
              },
            }
          )
          .then(res => {
            const user = res.data.data.me
            storage.setItem('user', JSON.stringify(user))
            storage.setItem('expired_at', addDays(30))
            navigate('/events')
          })
          .catch(err => console.log(err))
      })
      .catch(err => {
        if (err.response) {
          alert('Login failed! Kindly try again.')
        }
        this.setState({ loading: false })
      })
  }

  handleToggleShowPassword = () => {
    this.setState(state => ({
      showPassword: !state.showPassword,
    }))
  }

  render() {
    const { showPassword } = this.state
    return (
      <Layout>
        <h2 className="has-text-centered title is-3">Welcome Back!</h2>
        <form method="post" onSubmit={e => this.handleSubmit(e)}>
          <div className="field">
            <div className="control">
              <label>Email or mobile phone number*</label>
              <input
                className="input"
                name="email"
                type="text"
                onChange={e => this.handleChange(e)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label>Password*</label>
                <label onClick={() => this.handleToggleShowPassword()}>
                  {showPassword ? 'Hide' : 'Show'}
                </label>
              </div>
              <input
                className="input"
                name="password"
                type={showPassword ? 'text' : 'password'}
                onChange={e => this.handleChange(e)}
              />
            </div>
          </div>
          <div className="fd-center columns">
            <div className="column has-text-left" style={{ padding: 0 }}>
              <Link to="/forgot" className="links">
                Forgot your password?
              </Link>
            </div>
          </div>
          <div className="fd-center">
            <button
              type="submit"
              className={`button is-medium has-background-link has-text-white is-hidden-mobile ${
                this.state.loading ? 'is-loading' : ''
              }`}
            >
              Login
            </button>
            <div className="fd-central">
              <button
                type="submit"
                className={`button is-medium has-background-link has-text-white is-hidden-tablet is-fullwidth ${
                  this.state.loading ? 'is-loading' : ''
                }`}
              >
                Login
              </button>
            </div>
          </div>
          <div
            className="fd-center columns is-mobile"
            style={{ marginBottom: '10px' }}
          >
            <div
              className="column is-two-thirds has-text-left"
              style={{ padding: 0 }}
            >
              <label className="checkbox check-container">
                <input
                  type="checkbox"
                  name="remember"
                  defaultChecked
                  onChange={e => this.handleChange(e)}
                />
                <span className="checkmark"> </span>
                Keep me logged in
              </label>
            </div>
            <div className="column has-text-right" style={{ padding: 0 }}>
              <Link to="/create-login" className="links">
                Sign up
              </Link>
            </div>
          </div>
        </form>
      </Layout>
    )
  }
}

export default Login
