import React from 'react'
import Login from '../components/login'

const LoginPage = () => (
  <div>
    <Login />
  </div>
)

export default LoginPage
